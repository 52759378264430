<template>
  <b-overlay
    :show="modalLoading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <ValidationObserver
      ref="updateMerchantForm"
      v-slot="{ handleSubmit }"
      slim
    >
      <b-form
        class="my-8"
        @submit.prevent="handleSubmit(onClickMakeRedeliveryRequest)"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Remark"
              label-for="h-name"
              label-cols-lg="3"
            >
              <b-form-input
                id="name"
                v-model="form.remark"
                placeholder="Enter the Remark"
                type="text"
                :state="form.remark.length < remarkMaxChar"
                :maxlength="remarkMaxChar"
              />
              <small
                class="textarea-counter-value float-right"
                :class="form.remark.length >= remarkMaxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ form.remark.length }}</span> / {{ remarkMaxChar }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="ml-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="ml-1"
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
// import vSelect from 'vue-select'
const FlagRepository = RepositoryFactory.get('flag')
export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    waybill: [String, Array],
    // eslint-disable-next-line vue/require-default-prop
    setOpen: Function,
    // eslint-disable-next-line vue/require-default-prop
    refresh: Function,
  },
  data() {
    return {
      open: false,
      form: {
        remark: '',
      },
      modalLoading: false,
      loading: false,
      meta: {},
      filter: null,
      filterOn: [],
      remarkMaxChar: 125,
    }
  },
  methods: {
    //
    async onClickMakeRedeliveryRequest() {
      this.modalLoading = true
      try {
        let waybills = []

        // Check if 'waybill' is an array
        if (Array.isArray(this.waybill)) {
          // If it's an array, use all waybills in the payload
          waybills = this.waybill
        } else {
          // If it's a string, convert it to an array with a single element
          waybills = [this.waybill]
        }

        const payload = {
          waybill_numbers: waybills,
          type: 'flag_8',
          data: {
            remark: this.form.remark,
          },
        }

        await FlagRepository.createFlag(payload)
        this.showSuccessMessage('Flag made successfully')
        this.setOpen(false)
        this.refresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
