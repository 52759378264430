<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row class="mb-1">
        <b-col
          col="6"
          md="3"
        >
          <b-form-checkbox
            id="reversed"
            v-model="filters['is_reverse_order']"
            :value="true"
            :unchecked-value="false"
          >
            Reversed Orders Only
          </b-form-checkbox>
        </b-col>
        <b-col
          col="6"
          md="3"
        >
          <b-form-checkbox
            id="remarks"
            v-model="filters['order_remark']"
            :value="true"
            :unchecked-value="false"
          >
            Orders With Remarks
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="selectedStatusFilters"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
              multiple
              deselect-from-dropdown
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Customer Name"
            label-for="customer_name"
          >
            <b-form-input
              id="customer_name"
              v-model="filters['customer_name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Origin Warehouse"
            label-for="customer_name"
          >
            <v-select
              v-model="filters['origin_warehouse']"
              :options="warehouseOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Warehouse"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Destination Warehouse"
            label-for="customer_name"
          >
            <v-select
              v-model="filters['destination_warehouse']"
              :options="warehouseOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Warehouse"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Origin City"
            label-for="origin"
          >
            <v-select
              id="origin"
              v-model="filters['origin_city_id']"
              :options="cityOptions"
              :reduce="option => option.id"
              label="name"
              autocomplete="nope"
              placeholder="Select Origin City"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Destination City"
            label-for="destination"
          >
            <v-select
              id="destination"
              v-model="filters['destination_city_id']"
              :options="cityOptions"
              :reduce="option => option.id"
              label="name"
              autocomplete="nope"
              placeholder="Select Destination City"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Customer Mobile Number"
            label-for="customer_name"
          >
            <b-form-input
              id="customer_name"
              v-model="filters['customer_phone']"
              placeholder="Search"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Weight"
            label-for="weight"
          >
            <cleave
              id="weight"
              v-model="filters['orders.weight']"
              class="form-control"
              :raw="false"
              :options="options.delimiter"
              placeholder="0000 to 0000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Order Date"
            label-for="order_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['orders.created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['orders.created_at']"
                class="flatpickr-cancel"
                @click="clearDate('orders.created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant"
            label-for="merchant"
          >
            <v-select
              v-model="filters['merchant_id']"
              :options="merchantOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Merchant"
              @input="getMerchantBusinessList()"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant Business"
            label-for="merchant business"
          >
            <v-select
              v-model="selectedBusinessFilters"
              :options="merchantBusinessOptions"
              :reduce="option => option.id"
              label="business_name"
              placeholder="Select Merchant Business"
              multiple
              deselect-from-dropdown
            >
              <template v-slot:option="option">
                {{ option.business_name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-can="'create-walking-orders'"
          cols="6"
          md="3"
        >
          <b-form-group
            label="Walking Customer"
            label-for="senderName"
          >
            <v-select
              v-model="filters['walking_customer_id']"
              :options="walkingCustomers"
              :reduce="option => option.id"
              label="name"
              autocomplete="nope"
              placeholder="Select Walking Customer"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Rider"
            label-for="customer_name"
          >
            <v-select
              v-model="filters['rider']"
              :options="riderOptions"
              :reduce="option => option.id"
              label="first_name"
              placeholder="Select Rider"
            >
              <template v-slot:option="option">
                {{ option.first_name }} {{ option.last_name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Status Changed Date"
            label-for="customer_name"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['status_date']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s',}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="COD"
            label-for="cod"
          >
            <b-form-input
              id="cod"
              v-model="filters['orders.cod']"
              placeholder="0000 to 0000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Collected COD"
            label-for="collected_cod"
          >
            <b-form-input
              id="collected_cod"
              v-model="filters['orders.collected_cod']"
              placeholder="0000 to 0000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Delivery Charge"
            label-for="delivery_charge"
          >
            <b-form-input
              id="delivery_charge"
              v-model="filters['orders.delivery_charge']"
              placeholder="0000 to 0000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Temporary Warehouse"
            label-for="delivery_charge"
          >
            <v-select
              v-model="filters['orders.temporary_warehouse_id']"
              :options="warehouseOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Warehouse"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Order Flags"
            label-for="delivery_charge"
          >
            <v-select
              v-model="selectedFlagFilters"
              :options="flagOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Flags"
              multiple
              deselect-from-dropdown
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="General Search"
            label-for="general_search"
          >
            <b-form-input
              id="general_search"
              v-model="filters['search']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Delivery Attempts"
            label-for="delivery_attempts"
          >
            <b-form-input
              id="delivery_attempts"
              v-model="filters['delivery_attempts']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <div>
      <b-card>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              class="refresh-button d-flex align-items-center justify-content-center shadow-custom"
              :disabled="loading"
              @click="refreshAllOrderSummery()"
              :style="cardBorder"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50 refresh-icon"
              />
              <span class="align-middle refresh-span">Refreshed</span>
              <time-ago
                class="timeAgo"
                :datetime="refreshedDate"
                refresh
                locale="en"
                :long="longString"
                :style="{color: 'black'}"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <summary-card
            title="All ORDERS"
            :sum="orderOptions.all_orders.sum"
            :count="orderOptions.all_orders.count"
            :border-variant="selection === 'allOrders'? '' :'primary'"
            :bg-variant="selection === 'allOrders'? 'var(--primary)' :''"
            :text-variant="selection === 'allOrders'? 'white' :'#5c5b61'"
            @click.native="onSummaryCardClick('','allOrders')"
          />
          <summary-card
            :title="`TO BE ${statusOptions.filter(option => option.key === 'key_13')[0].name}`"
            :sum="orderOptions.to_be_delivered.sum"
            :count="orderOptions.to_be_delivered.count"
            :border-variant="selection === 'to_be_delivered'? '' :'primary'"
            :bg-variant="selection === 'to_be_delivered'? 'var(--primary)' :''"
            :text-variant="selection === 'to_be_delivered'? 'white' :'#5c5b61'"
            @click.native="onSummaryCardClick('key_6,key_12','to_be_delivered')"
          />
          <summary-card
            :title="`TO BE ${statusOptions.filter(option => option.key === 'key_20')[0].name}`"
            :sum="orderOptions.to_be_returned.sum"
            :count="orderOptions.to_be_returned.count"
            :border-variant="selection === 'to_be_returned'? '' :'primary'"
            :bg-variant="selection === 'to_be_returned'? 'var(--primary)' :''"
            :text-variant="selection === 'to_be_returned'? 'white' :'#5c5b61'"
            @click.native="onSummaryCardClick('key_18,key_19','to_be_returned')"
          />
          <summary-card
            :title="statusOptions.filter(option => option.key === 'key_13')[0].name"
            :sum="orderOptions.delivered.sum"
            :count="orderOptions.delivered.count"
            :border-variant="selection === 'delivered'? '' :'primary'"
            :bg-variant="selection === 'delivered'? 'var(--primary)' :''"
            :text-variant="selection === 'delivered'? 'white' :'#5c5b61'"
            @click.native="onSummaryCardClick('key_7,key_13,key_14','delivered')"
          />
          <summary-card
            title="All RETURN ORDERS"
            :sum="orderOptions.returned.sum"
            :count="orderOptions.returned.count"
            :border-variant="selection === 'returned'? '' :'primary'"
            :bg-variant="selection === 'returned'? 'var(--primary)' :''"
            :text-variant="selection === 'returned'? 'white' :'#5c5b61'"
            @click.native="onSummaryCardClick('key_20,key_21','returned')"
          />
        </b-row>
      </b-card>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row
              cols="12"
              class="mb-0"
            >
              <b-col
                md="3"
              >
                <b-form-group
                  label="Waybill Number"
                  label-for="waybill_number"
                >
                  <b-form-input
                    id="waybill_number"
                    v-model="filters['waybill_number']"
                    placeholder="Search"
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="3"
              >
                <b-form-group
                  label="Order Number"
                  label-for="order_no"
                >
                  <b-form-input
                    id="order_number"
                    v-model="filters['order_no']"
                    placeholder="Search"
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
              <b-col
                class="d-flex justify-content-end align-items-center mb-md-0"
              >
                <b-tooltip
                  target="print_button"
                  triggers="hover"
                  variant="primary"
                >
                  Print Waybills
                </b-tooltip>
                <div
                  id="print_button"
                  class="d-flex align-items-center"
                >
                  <b-dropdown
                    id="printDropdown"
                    split-class="custom-button-color-blur unable-pointer"
                    toggle-class="custom-button-color"
                    size="sm"
                    role="menu"
                    split
                    class="mr-1"
                    :disabled="selectedWaybillArray.length === 0"
                  >
                    <template #button-content>
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1xci1jb2RlIj48cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiB4PSIzIiB5PSIzIiByeD0iMSIvPjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHg9IjE2IiB5PSIzIiByeD0iMSIvPjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHg9IjMiIHk9IjE2IiByeD0iMSIvPjxwYXRoIGQ9Ik0yMSAxNmgtM2EyIDIgMCAwIDAtMiAydjMiLz48cGF0aCBkPSJNMjEgMjF2LjAxIi8+PHBhdGggZD0iTTEyIDd2M2EyIDIgMCAwIDEtMiAySDciLz48cGF0aCBkPSJNMyAxMmguMDEiLz48cGF0aCBkPSJNMTIgM2guMDEiLz48cGF0aCBkPSJNMTIgMTZ2LjAxIi8+PHBhdGggZD0iTTE2IDEyaDEiLz48cGF0aCBkPSJNMjEgMTJ2LjAxIi8+PHBhdGggZD0iTTEyIDIxdi0xIi8+PC9zdmc+"
                      >
                    </template>
                    <b-dropdown-item
                      v-for="(option, index) in layoutDropdownOptions"
                      :key="index"
                      @click="onClickPrintDispatch(option.id)"
                    >
                      {{ option.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <ReportTableButtons
                  :items="rows"
                  :json_fieldz="json_fields"
                  :name="`AllOrders - Page ${page}.xls`"
                  :bulkname="`AllOrders.xls`"
                  :fetch="getOrderListQueue"
                  :table-fields="tableFields"
                  :table-data="tableData"
                  :title="`All Orders -${ meta.from } to ${ meta.to } of ${ meta.total } entries`"
                  :show_custom_popup="true"
                  :queue-enable="true"
                  :queue-permission="`download-all-orders`"
                  @refresh="onClickRefresh"
                />
              </b-col>
            </b-row>
            <b-row class="d-sm-flex align-items-start">
              <b-col class="pt-1 pt-md-0">
                <div class="font-weight-bolder">
                  {{ `${selectedWaybillArray.length} record${selectedWaybillArray.length === 1 ? '':'s'}` }} selected
                </div>
              </b-col>
              <b-col
                :cols="{ sm: 12, md: 10 }"
                class="d-block d-flex justify-content-end align-items-center mb-md-0 actionButtons pr-1"
              >
                <div class="d-flex flex-row">
                  <b-tooltip
                    target="ChangeDefaultWarehouse"
                    triggers="hover"
                    variant="primary"
                  >
                    Change Default Warehouse
                  </b-tooltip>
                  <div id="ChangeDefaultWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_2')"
                      v-can="'edit-orders'"
                      variant="primary"
                      class="ml-1 mt-1 mt-md-0 btn-relief-primary"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onChangeDefaultWarehouse()"
                    >
                      <mdicon
                        name="HomeCircleOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="AssignPickupRider"
                    triggers="hover"
                    variant="info"
                  >
                    Assign Pickup Rider
                  </b-tooltip>
                  <div id="AssignPickupRider">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_2') || selectedStatusFilters.includes('key_4')"
                      v-can="'edit-orders'"
                      variant="info"
                      class="ml-1 mt-1 mt-md-0 btn-relief-info outline"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onAssignPickupRider()"
                    >
                      <mdicon
                        name="BicycleBasket"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="CancelClick"
                    triggers="hover"
                    variant="danger"
                  >
                    Cancel
                  </b-tooltip>
                  <div id="CancelClick">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_2')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onCancelClick()"
                    >
                      <mdicon
                        name="DeleteCircleOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="Pickup"
                    triggers="hover"
                    variant="info"
                  >
                    Pick Up
                  </b-tooltip>
                  <div id="Pickup">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_5')"
                      v-can="'edit-orders'"
                      variant="info"
                      class="ml-1 mt-1 mt-md-0 btn-relief-info"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onPickupClick()"
                    >
                      <mdicon
                        name="Dolly"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="PickupDeliver"
                    triggers="hover"
                    variant="success"
                  >
                    Deliver By Pickup Rider
                  </b-tooltip>
                  <div id="PickupDeliver">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_6')"
                      v-can="'edit-orders'"
                      variant="success"
                      class="ml-1 mt-1 mt-md-0 btn-relief-success"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onPickupDeliverClick()"
                    >
                      <mdicon
                        name="Bike"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="Dispatch"
                    triggers="hover"
                    variant="info"
                  >
                    Dispatch To Origin Warehouse
                  </b-tooltip>
                  <div id="Dispatch">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_6')"
                      v-can="'edit-orders'"
                      variant="info"
                      class="ml-1 mt-1 mt-md-0 btn-relief-info"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onDispatchClick()"
                    >
                      <mdicon
                        name="HomeOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="ReceiveToOriginWarehouse"
                    triggers="hover"
                    variant="primary"
                  >
                    Receive to Origin Warehouse
                  </b-tooltip>
                  <div id="ReceiveToOriginWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_2') || selectedStatusFilters.includes('key_4') || selectedStatusFilters.includes('key_8')"
                      v-can="'edit-orders'"
                      variant="primary"
                      class="ml-1 mt-1 mt-md-0 btn-relief-primary"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReceiveToOriginWarehouse()"
                    >
                      <mdicon
                        name="HomeMapMarker"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="DispatchFromOriginWarehouse"
                    triggers="hover"
                    variant="primary"
                  >
                    Dispatch from Origin Warehouse
                  </b-tooltip>
                  <div id="DispatchFromOriginWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_9') || selectedStatusFilters.includes('key_29')"
                      v-can="'edit-orders'"
                      variant="primary"
                      class="ml-1 mt-1 mt-md-0 btn-relief-primary"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickDispatchFromOriginWarehouse()"
                    >
                      <mdicon
                        name="HomeExportOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="ReceiveToDestinationWarehouse"
                    triggers="hover"
                    variant="info"
                  >
                    Receive to Destination Warehouse
                  </b-tooltip>
                  <div id="ReceiveToDestinationWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_8') || selectedStatusFilters.includes('key_10')"
                      v-can="'edit-orders'"
                      variant="info"
                      class="ml-1 mt-1 mt-md-0 btn-relief-info"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReceiveToDestinationWarehouse()"
                    >
                      <mdicon
                        name="HomeGroup"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="AssignDestinationRider"
                    triggers="hover"
                    variant="info"
                  >
                    Assign Destination Rider
                  </b-tooltip>
                  <div id="AssignDestinationRider">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_9') || selectedStatusFilters.includes('key_11') || selectedStatusFilters.includes('key_16')"
                      v-can="'edit-orders'"
                      variant="info"
                      class="ml-1 mt-1 mt-md-0 btn-relief-info"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickAssignDestinationRider()"
                    >
                      <mdicon
                        name="BikeFast"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="Deliver"
                    triggers="hover"
                    variant="success"
                  >
                    Delivered
                  </b-tooltip>
                  <div id="Deliver">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_12')"
                      v-can="'edit-orders'"
                      variant="success"
                      class="ml-1 mt-1 mt-md-0 btn-relief-success"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickDeliver()"
                    >
                      <mdicon
                        name="BriefcaseCheckOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="PartiallyDeliver"
                    triggers="hover"
                    variant="success"
                  >
                    Partially Delivered
                  </b-tooltip>
                  <div id="PartiallyDeliver">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_12')"
                      v-can="'edit-orders'"
                      variant="success"
                      class="ml-1 mt-1 mt-md-0 btn-relief-success"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickPartiallyDeliver()"
                    >
                      <mdicon
                        name="TruckCheckOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="Return"
                    triggers="hover"
                    variant="warning"
                  >
                    Returned To Destination Warehouse
                  </b-tooltip>
                  <div id="Return">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_12')"
                      v-can="'edit-orders'"
                      variant="warning"
                      class="ml-1 mt-1 mt-md-0 btn-relief-warning"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReturn()"
                    >
                      <mdicon
                        name="HomeGroup"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="bulkFlag"
                    triggers="hover"
                    variant="primary"
                  >
                    Bulk Flag
                  </b-tooltip>
                  <div id="bulkFlag">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_17') || selectedStatusFilters.includes('key_18') || selectedStatusFilters.includes('key_19')"
                      v-can="'edit-orders'"
                      variant="primary"
                      class="ml-1 mt-1 mt-md-0 btn-relief-warning"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickBulkFlagUpdate()"
                    >
                      <feather-icon
                        icon="FlagIcon"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="Reschedule"
                    triggers="hover"
                    variant="warning"
                  >
                    Reschedule
                  </b-tooltip>
                  <div id="Reschedule">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_15') || selectedStatusFilters.includes('key_17')"
                      v-can="'edit-orders'"
                      variant="warning"
                      class="ml-1 mt-1 mt-md-0 btn-relief-warning"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReschedule()"
                    >
                      <mdicon
                        name="Update"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="DeliveryFailed"
                    triggers="hover"
                    variant="danger"
                  >
                    Failed To Deliver
                  </b-tooltip>
                  <div id="DeliveryFailed">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_15')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickDeliveryFailed()"
                    >
                      <mdicon
                        name="HomeAlertOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="ReturnToOriginWarehouse"
                    triggers="hover"
                    variant="danger"
                  >
                    Return to Origin Warehouse
                  </b-tooltip>
                  <div id="ReturnToOriginWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_17')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReturnToOriginWarehouse()"
                    >
                      <mdicon
                        name="TruckDelivery"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="FailedReceiveToOriginWarehouse"
                    triggers="hover"
                    variant="danger"
                  >
                    Receive to Origin Warehouse [Delivery Failed]
                  </b-tooltip>
                  <div id="FailedReceiveToOriginWarehouse">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_18')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickFailedReceiveToOriginWarehouse()"
                    >
                      <mdicon
                        name="AlertDecagramOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="return_to_client"
                    triggers="hover"
                    variant="danger"
                  >
                    Return to Client
                  </b-tooltip>
                  <div id="return_to_client">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_19')|| selectedStatusFilters.includes('key_17')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReturnToClient()"
                    >
                      <mdicon
                        name="AccountAlertOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div
                  v-can="'edit-orders'"
                >
                  <b-tooltip
                    target="receive_failed_order_wc"
                    triggers="hover"
                    variant="warning"
                  >
                    Receive Failed Order Walking Customer
                  </b-tooltip>
                  <div id="receive_failed_order_wc">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_20')"
                      v-can="'create-walking-orders'"
                      variant="warning"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReceiveFailedOrderWalkingCustomer()"
                    >
                      <mdicon
                        name="AccountAlertOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="InvalidDestination"
                    triggers="hover"
                    variant="danger"
                  >
                    Invalid Destination
                  </b-tooltip>
                  <div id="InvalidDestination">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_11')|| selectedStatusFilters.includes('key_12')|| selectedStatusFilters.includes('key_15')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickInvalidDestination()"
                    >
                      <mdicon
                        name="HomeAlert"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="ReturnToOriginWarehouseInvalidDestination"
                    triggers="hover"
                    variant="danger"
                  >
                    Return to Origin Warehouse [Invalid Destination]
                  </b-tooltip>
                  <div id="ReturnToOriginWarehouseInvalidDestination">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_26')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReturnToOriginWarehouseInvalidDestination()"
                    >
                      <mdicon
                        name="TruckDelivery"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="ReceiveToOriginWarehouseInvalidDestination"
                    triggers="hover"
                    variant="danger"
                  >
                    Received to Origin Warehouse [Invalid Destination]
                  </b-tooltip>
                  <div id="ReceiveToOriginWarehouseInvalidDestination">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_27')"
                      v-can="'edit-orders'"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickReceivedToOriginWarehouseInvalidDestination()"
                    >
                      <mdicon
                        name="HomeImportOutline"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="DifferentDestination"
                    triggers="hover"
                    variant="primary"
                  >
                    Change Destination
                  </b-tooltip>
                  <div id="DifferentDestination">
                    <b-button
                      v-if="selectedStatusFilters.includes('key_28')"
                      v-can="'edit-orders'"
                      variant="primary"
                      class="ml-1 mt-1 mt-md-0 btn-relief-primary"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickDifferentDestination()"
                    >
                      <mdicon
                        name="SwapHorizontal"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-tooltip
                    target="reverse_button"
                    triggers="hover"
                    variant="danger"
                  >
                    Reverse Orders
                  </b-tooltip>
                  <div id="reverse_button">
                    <b-button
                      v-can="'add-reversal'"
                      :disabled="selectedWaybillArray.length === 0"
                      variant="danger"
                      class="ml-1 mt-1 mt-md-0 btn-relief-danger bg-darken-2"
                      size="sm"
                      :style="selectedWaybillArray.length === 0 ? 'pointer-events:none;':''"
                      @click="onClickReverse()"
                    >
                      <mdicon
                        name="UndoVariant"
                        size="15"
                      />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            variant="light"
            opacity="0.30"
            blur="10px"
            rounded="sm"
          >
            <b-table
              id="all-order-table"
              :key="filters.rider"
              v-sortable="filters.rider ? sortableOptions : null"
              responsive
              striped
              hover
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
              class="hide-vertical-scroll"
              @sort-changed="sortChanged"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #head(id)>
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template #cell(id)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedWaybillArray"
                        :value="data.item.waybill_number"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(waybill_number)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    :text="avatarText(data.item.customer_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      :class="{'font-weight-bolder ml-1 text-danger': data.item.temporary_warehouse_id !=null ,'font-weight-bolder ml-1 text-primary':data.item.temporary_warehouse_id ===null}"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(merchant.name)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="">
                      {{ __orderMerchantName(data.item) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2">
                      {{ data.item.customer_address }}
                    </div>
                    <div class="font-small-2">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(origin_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.origin_city.name }}
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(destination_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.destination_city.name }}
                    <!--                    </b-badge>-->
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #head(delivery_possibility)>
                <div class="text-left d-flex align-items-center">
                  AI DSR (Beta)
                  <feather-icon
                    v-b-tooltip.hover.html="'<div style=&quot;text-align: left; white-space: normal;&quot;>AI-Powered Delivery Success Rate is the ability to predict the risk of customer returns using artificial intelligence. <a href=&quot;https://www.canva.com/design/DAGOAKRJAMQ/zQlhkuq7HgtIR7Qay5fjIA/view?utm_content=DAGOAKRJAMQ&utm_campaign=designshare&utm_medium=link&utm_source=editor&quot; target=&quot;_blank&quot;>More Details</a><br><br>0% - 30%: High chance of return<br>30% - 65%: Average chance of delivery<br>65% - 100%: High chance of delivery<br><br>This is a Beta Version.</div>'"
                    icon="InfoIcon"
                    size="16"
                    class="ml-1"
                  />
                </div>
              </template>
              <template #cell(delivery_possibility)="data">
                <div
                  v-if="data.item.delivery_possibility"
                  :style="{ width:'150px'}"
                >
                  {{ data.item.delivery_possibility }}%
                  <b-progress
                    :value="data.item.delivery_possibility"
                    :variant="getProgressVariant(data.item.delivery_possibility)"
                    :class="getProgressBarClass(data.item.delivery_possibility)"
                    max="100"
                  />
                </div>
                <div
                  v-if="!data.item.delivery_possibility"
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>

                </div>
              </template>
              <template #cell(finance)="data">
                <div :class="`width-150 d-flex justify-content-center align-items-start ${data.item.merchant.merchant_setting ? 'flex-column' : 'flex-column-reverse'}`">
                  <div v-if="data.item.merchant.merchant_setting">
                    <b>Total&nbsp;:</b>&nbsp;&nbsp;{{ __numberWithCommas(data.item.cod, true) || '---' }}
                  </div>
                  <div v-if="!data.item.merchant.merchant_setting">
                    <b>Balance&nbsp;:</b>&nbsp;&nbsp;{{ __numberWithCommas(data.item.delivery_charge - data.item.collected_cod, true ) || '---' }}
                  </div>
                  <div style="height:8px;" />
                  <div><b>{{ data.item.merchant.merchant_setting ? 'Collected' : 'Advance' }}&nbsp;:</b>&nbsp;&nbsp;{{ __numberWithCommas(data.item.collected_cod, true) || '---' }}</div>
                  <div
                    v-if="store.getters.permissions.filter(permission => permission === 'view-delivery-charge').length > 0"
                  >
                    <div style="height:8px;" />
                    <div><b>D-Charge&nbsp;:</b>&nbsp;&nbsp;{{ __numberWithCommas(data.item.delivery_charge, true) || '---' }}</div>
                  </div>
                </div>
              </template>
              <template #cell(weight)="data">
                {{ (data.value).toLocaleString() }}
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(rider_name)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ `${data.item.rider ? data.item.rider.first_name : 'N/A'} ${data.item.rider && data.item.rider.last_name ? data.item.rider.last_name : ''}` }}
                    </div>
                    <div class="font-small-2">
                      {{ data.item.rider && data.item.rider.email ? data.item.rider.email : '' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(latest_status_updated_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>

              <!-- ... Flag Componant ... -->
              <template #cell(order_flags)="data">
                <div class="d-flex align-items-center">
                  <FlagDisplay :flags="data.item.order_flags" />
                </div>
              </template>

              <template #cell(remark)="data">
                <div>
                  <b-badge
                    :variant="data.value > 0 ? 'light-info':'light-danger'"
                    @click="onClickMakeRemark(data.item.id)"
                  >
                    {{ data.value === 0 ? 'None' : `${data.value} remark${data.value === 1 ? '' : 's'}` }}
                  </b-badge>
                </div>
              </template>

              <template #cell(order_current_status.key)="data">
                <div>
                  <order-status-badge :status-key="data.value" />
                </div>
                <div
                  v-if="data.item.is_reversed_order === true"
                  class="mt-1 d-flex"
                >
                  <div>
                    <b-tooltip
                      :target="(data.item.id + data.item.reversal.current_status.key)"
                      triggers="hover"
                      :variant="data.item.reversal.current_status.color"
                    >
                      {{ data.item.reversal.current_status.name }}
                    </b-tooltip>
                    <b-avatar
                      :id="(data.item.id + data.item.reversal.current_status.key)"
                      size="20"
                      :variant="('light-' + data.item.reversal.current_status.color)"
                    >
                      <feather-icon
                        :icon="data.item.reversal.current_status.icon"
                        size="10"
                        class="text-body align-middle"
                      />
                    </b-avatar>
                  </div>
                &nbsp;&nbsp;
                  <div>
                    <feather-icon
                      icon="ChevronsLeftIcon"
                      class="text-body align-middle"
                    />
                  </div>
                &nbsp;&nbsp;
                  <div>
                    <b-tooltip
                      :target="(data.item.id + data.item.reversal.previous_status.key)"
                      triggers="hover"
                      :variant="data.item.reversal.previous_status.color"
                    >
                      {{ data.item.reversal.previous_status.name }}
                    </b-tooltip>
                    <b-avatar
                      :id="(data.item.id + data.item.reversal.previous_status.key)"
                      size="20"
                      :variant="('light-' + data.item.reversal.previous_status.color)"
                    >
                      <feather-icon
                        :icon="data.item.reversal.previous_status.icon"
                        size="10"
                        class="text-body align-middle"
                      />
                    </b-avatar>
                  </div>
                </div>
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'show-orders'"
                    @click="onClickViewOrder(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.order_current_status.after_statuses.length > 0"
                    v-can="'edit-orders'"
                    @click="onClickEditOrder(data.item.id)"
                  >
                    <feather-icon icon="EditIcon" />
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!(data.item.order_current_status.key === 'key_1')"
                    v-can="'add-reversal'"
                    @click="onClickReverse(data.item.id)"
                  >
                    <feather-icon icon="CornerDownLeftIcon" />
                    Reverse Order
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'order-remark-show'"
                    @click="onClickMakeRemark(data.item.id)"
                  >
                    <feather-icon icon="BookmarkIcon" />
                    Make Remark
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'order-flag-view'"
                    @click="onClickFlagDetail(data.item.waybill_number, data.item.order_current_status.key, data.item.id)"
                  >
                    <feather-icon icon="FlagIcon" />
                    Flags
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.rider_signature"
                    :href="`${bucketUrl}${data.item.rider_signature}`"
                    target="_blank"
                  >
                    <feather-icon icon="Edit2Icon" />
                    View Receiver's Signature
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.feedback_url != null"
                    v-can="'view-feedback-link'"
                    @click="onClickViewFeedBack(data.item.id, data.item.feedback_url)"
                  >
                    <feather-icon icon="StarIcon" />
                    View FeedBack
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row
                cols="12"
              >
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block width-125"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  md="9"
                >
                  <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
    <make-remark ref="add_make_remark" />
    <view-feedback ref="view_feedback" />
    <make-future-request ref="add_future_request" />
    <flag-details ref="flag_detail" />
    <view-order-modal ref="viewOrderModal" />
    <reverse-order-modal
      ref="reverseOrderModal"
      :selected_waybill_array="selectedWaybillArray"
    />
    <bulk-flag-update
      ref="BulkFlagUpdate"
      :selected_waybill_array="selectedWaybillArray"
    />
    <update-order-details ref="editOrderModal" />
    <bulk-action-modal
      ref="bulkActionModal"
      :form_structure="formStructure"
      :status_key="status_key"
      :selected_waybill_array="selectedWaybillArray"
      :rows="rows"
      :order_settings="orderSettings"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BOverlay,
  BBadge,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BTooltip,
  BProgress,
  // BAlert,
} from 'bootstrap-vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { bucketUrl } from '@/constants/config'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import MakeRemark from '@/components/AllOrders/Remark/MakeRemark.vue'
import ViewFeedback from '@/components/AllOrders/FeedBack/ViewFeedBack.vue'
import FlagDetails from '@/components/AllOrders/FlagDetails.vue'
import MakeFutureRequest from '@/components/AllOrders/MakeFutureRequest.vue'
import UpdateOrderDetails from '@/components/AllOrders/UpdateOrderDetails.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import sortable from '@/libs/sortable'
import store from '@/store/index'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import ReverseOrderModal from '@/components/AllOrders/ReverseOrderModal.vue'
import BulkActionModal from '@/components/status-flow/bulk-actions/BulkActionModal.vue'
import BulkFlagUpdate from '@/components/AllOrders/BulkFlagUpdate.vue'
import FlagDisplay from '@/components/AllOrders/OrderFlags/FlagDisplay.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import SummaryCard from '@core/components/summary-card/SummaryCard.vue'
import KeyFour from '@/components/status-flow/KeyFour.vue'
import KeyFive from '@/components/status-flow/KeyFive.vue'
import KeySeven from '@/components/status-flow/KeySeven.vue'
import KeyNine from '@/components/status-flow/KeyNine.vue'
import KeyTen from '@/components/status-flow/KeyTen.vue'
import KeyTwelve from '@/components/status-flow/KeyTwelve.vue'
import KeyFourteen from '@/components/status-flow/KeyFourteen.vue'
import KeyFifteen from '@/components/status-flow/KeyFifteen.vue'
import KeySixteen from '@/components/status-flow/KeySixteen.vue'
import KeySeventeen from '@/components/status-flow/KeySeventeen.vue'
import KeyTwentySix from '@/components/status-flow/KeyTwentySix.vue'
import KeyTwentyNine from '@/components/status-flow/KeyTwentyNine.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import { TimeAgo } from 'vue2-timeago'
import 'vue2-timeago/dist/vue2-timeago.css'

const ResourceRepository = RepositoryFactory.get('resource')
const orderRepository = RepositoryFactory.get('order')
const merchantRepository = RepositoryFactory.get('merchant')
const riderRepository = RepositoryFactory.get('rider')
const OrderSettingsRepository = RepositoryFactory.get('orderSetting')
const WalkingCustomerRepository = RepositoryFactory.get('walkingCustomer')
const LayoutRepository = RepositoryFactory.get('layout')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
    sortable,
  },
  components: {
    TimeAgo,
    BTooltip,
    BAvatar,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BBadge,
    vSelect,
    OrderStatusBadge,
    BDropdown,
    BDropdownItem,
    MakeRemark,
    ViewFeedback,
    MakeFutureRequest,
    FlagDetails,
    flatPickr,
    Cleave,
    ViewOrderModal,
    SummaryCard,
    BCardActions,
    UpdateOrderDetails,
    BulkActionModal,
    ReverseOrderModal,
    FeatherIcon,
    BProgress,
    FilterButtons,
    BulkFlagUpdate,
    FlagDisplay,
    // BAlert,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      layoutDropdownOptions: {},
      longString: true,
      refreshedDate: new Date(),
      store,
      filters: {},
      selectedStatusFilters: [],
      statusOptions: [],
      warehouseOptions: [],
      flagOptions: [],
      selectedFlagFilters: [],
      cityOptions: [],
      riderOptions: [],
      merchantOptions: [],
      walkingCustomers: [],
      selectedBusinessFilters: [],
      merchantBusinessOptions: [],
      merchant_business_id: [],
      options: {
        delimiter: {
          delimiter: ' to ',
          blocks: [4, 4],
          uppercase: true,
        },
      },
      // Filters End
      avatarText,
      bucketUrl,
      total: 0,
      meta: {},
      loading: false,
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
      totalRows: 1,
      page: 1,
      selectedKey: '',
      orderSettings: null,
      fields: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'waybill_number',
          label: 'WayBill Number',
          sortable: true,
        },
        {
          key: 'merchant.name',
          label: 'Merchant',
          sortable: true,
        },
        {
          key: 'origin_warehouse',
          label: 'Origin',
          sortable: true,
          thClass: ' text-left',
          tdClass: ' text-left',
        },
        {
          key: 'destination_warehouse',
          label: 'Destination',
          sortable: true,
          thClass: ' text-left',
          tdClass: ' text-left',
        },
        {
          key: 'customer_email',
          label: 'Customer',
          sortable: true,
          thClass: ' text-left',
          tdClass: ' text-left',
        },
        {
          key: 'finance',
          label: 'Finance',
          sortable: false,
        },
        {
          key: 'weight',
          label: 'Weight',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_attempts',
          label: 'Delivery Attempts',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rider_name',
          label: 'Rider',
          sortable: true,
        },
        {
          key: 'latest_status_updated_at',
          label: 'Status Changed Date',
          sortable: false,
          thClass: ' text-left',
          tdClass: ' text-left',
        },
        { key: 'order_flags', label: 'Flags', sortable: false },
        {
          key: 'remark', label: 'Remarks', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //  key: 'delivery_possibility',
        //  label: 'AI DSR (Beta)',
        //  sortable: true,
        //  thClass: ' text-left',
        //  tdClass: ' text-center',
        // },
        {
          key: 'order_current_status.key',
          label: 'Status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      allData: [],
      selectedWaybillArray: [],
      json_fields: {
        'Waybill Number': 'waybill_number',
        Merchant: {
          field: 'merchant.name',
          callback: value => `"${value}"`,
        },
        'Merchant Business': {
          field: 'merchant_business.business_name',
          callback: value => (value ? `"${value}"` : 'N/A'),
        },
        'Origin City': {
          field: 'origin_city.name',
          callback: value => `"${value}"`,
        },
        'Origin Warehouse': {
          field: 'origin_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Destination City': {
          field: 'destination_city.name',
          callback: value => `"${value}"`,
        },
        'Destination Warehouse': {
          field: 'destination_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: 'cod',
        'Collected Cod': 'collected_cod',
        'Delivery Charge': 'delivery_charge',
        Weight: 'weight',
        'Delivery Attempts': 'delivery_attempts',
        Rider: {
          callback: value => `"${value.rider !== null ? `${value.rider.first_name}` : 'N/A'} ${value.rider !== null && value.rider.last_name !== null ? `${value.rider.last_name}` : ''}"`,
        },
        'Status Changed Date': {
          field: 'latest_status_updated_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Created At': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Flag: {
          field: 'order_flags',
          callback: value => {
            const flagNames = value.map(flag => flag.name).join(', ')
            return `"${flagNames}"`
          },
        },
        Description: {
          field: 'description',
          callback: value => `"${value}"`,
        },
        Status: 'order_current_status.name',
      },
      status_key: '',
      formStructure: [],
      sortingOrder: null,
      sortableOptions: {
        chosenClass: 'is-selected',
        animation: 150,
        onEnd: event => {
          if (this.filters.rider) {
            this.updateRiderSortIndex(event.newIndex)
          }
        },
        onStart: event => {
          this.sortingOrder = this.rows[event.oldIndex]
        },
      },
      selection: 'allOrders',
      orderOptions: {
        all_orders: {},
        to_be_delivered: {},
        to_be_returned: {},
        delivered: {},
        returned: {},
      },
      format: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
      tableFields: [
        'Waybill Number',
        'Merchant',
        'Origin City',
        'Origin Warehouse',
        'Destination City',
        'Destination Warehouse',
        'Customer Name',
        'Delivery Probability',
        'Customer Email',
        'Customer Address',
        'Customer Phone',
        { title: 'COD', styles: { halign: 'right' } },
        { title: 'Collected Cod', styles: { halign: 'right' } },
        { title: 'Delivery Charge', styles: { halign: 'right' } },
        { title: 'Weight', styles: { halign: 'right' } },
        'Created At',
        'Description',
        'Status',
      ],
      selectAllCheckbox: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    tableData() {
      return this.rows.map(item => [item.waybill_number, item.merchant.name, item.origin_city.name, item.origin_warehouse.name, item.destination_city.name, item.destination_warehouse.name, item.customer_name, item.customer_email, item.customer_address, [item.customer_phone, item.customer_secondary_phone], (item.cod).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), item.collected_cod ? (item.collected_cod).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-', (item.delivery_charge).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), item.weight, item.created_at ? new Date(item.created_at).toLocaleDateString('en-GB', this.format) : '-', item.description, item.order_current_status.name])
    },
    cardBorder() {
      return {
        border: '1px solid var(--primary) !important',
      }
    },
  },
  watch: {
    page() {
      this.getOrderList()
    },
    perPage() {
      this.getOrderList()
    },
    selectedStatusFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          status: keyList,
        }
      },
      deep: true,
    },
    selectedFlagFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          order_flag: keyList,
        }
      },
      deep: true,
    },
    selectedBusinessFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          ...this.filters,
          merchant_business_id: keyList,
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getOrderList()
    this.readOrderStatuses()
    this.getWarehouseList()
    this.readOrderSetting()
    this.getOrderSummary()
    await this.getCityList()
    await this.getFlagList()
    await this.getRiderList()
    await this.getMerchantList()
    await this.getWalkingCustomerList()
    await this.getWaybillLayout()
  },

  methods: {
    async getWaybillLayout() {
      try {
        const { data } = (await LayoutRepository.getActiveWaybillLayout())
        this.layoutDropdownOptions = data.data
      } catch (e) {
        this.showErrorMessage('Couldn\'t fetch waybill layout!')
      }
    },
    getProgressVariant(value) {
      if (value >= 0.00 && value <= 30.00) return 'danger'
      if (value >= 30.01 && value <= 64.00) return 'warning'
      if (value >= 64.01 && value <= 100) return 'success'
      return ''
    },
    getProgressBarClass(value) {
      if (value >= 0.00 && value <= 30.00) return 'progress-bar-danager'
      if (value >= 30.01 && value <= 64.00) return 'progress-bar-warning'
      if (value >= 64.01 && value <= 100) return 'progress-bar-success'
      return ''
    },
    async getOrderList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderList(this.page, this.filterQuery, this.perPage, (this.filters.rider ? 'sort=rider_sort_index' : '')))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getOrderListQueue() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderListQueue(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getOrderSummary() {
      try {
        const summaryData = localStorage.getItem('all-orders-summery')
        const refreshDate = localStorage.getItem('all-orders-refresh-time')

        if (summaryData && refreshDate) {
          // Parse the JSON string back into a JavaScript object
          this.allOrdersSummery = JSON.parse(summaryData)
          this.refreshedDate = JSON.parse(refreshDate)
        } else {
          const { data } = (await orderRepository.getOrderSummery()).data
          this.allOrdersSummery = data
          this.refreshedDate = new Date()

          localStorage.setItem('all-orders-refresh-time', JSON.stringify(this.refreshedDate))
          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('all-orders-summery', JSON.stringify(this.allOrdersSummery))
        }
        this.orderOptions = this.allOrdersSummery
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter()).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getFlagList() {
      this.flagOptions = localStorage.getItem('flag_list') ? JSON.parse(localStorage.getItem('flag_list')) : (await ResourceRepository.getFlagList()).data.data
      if (!localStorage.getItem('flag_list')) localStorage.setItem('flag_list', JSON.stringify(this.flagOptions))
    },
    async readOrderStatuses() {
      this.statusOptions = localStorage.getItem('order_statuses') ? JSON.parse(localStorage.getItem('order_statuses')) : (await orderRepository.getFullStatusList()).data.data
      if (!localStorage.getItem('order_statuses')) localStorage.setItem('order_statuses', JSON.stringify(this.statusOptions))
    },
    async getRiderList() {
      this.riderOptions = localStorage.getItem('rider_list') ? JSON.parse(localStorage.getItem('rider_list')) : (await riderRepository.getAllRiderDropDown()).data.data
      if (!localStorage.getItem('rider_list')) localStorage.setItem('rider_list', JSON.stringify(this.riderOptions))
    },
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await merchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
    },
    async getMerchantBusinessList() {
      try {
        this.loading = true
        this.selectedBusinessFilters = []
        this.merchant_id = this.filters.merchant_id
        const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(this.merchant_id)).data
        this.merchantBusinessOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getWalkingCustomerList() {
      this.walkingCustomers = localStorage.getItem('walking_customer_list') ? JSON.parse(localStorage.getItem('walking_customer_list')) : (await WalkingCustomerRepository.getWalkingCustomerDropdownList()).data.data
      if (!localStorage.getItem('walking_customer_list')) localStorage.setItem('walking_customer_list', JSON.stringify(this.walkingCustomers))
    },
    async getWarehouseList() {
      try {
        this.warehouseOptions = localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : (await ResourceRepository.getWarehouseListForDropDown()).data.data
        if (!localStorage.getItem('warehouse_list')) localStorage.setItem('warehouse_list', JSON.stringify(this.warehouseOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readOrderSetting() {
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async updateRiderSortIndex(newIndex) {
      try {
        const payload = {
          rider_id: this.filters.rider,
          old_index: this.sortingOrder.rider_sort_index,
          new_index: this.rows[newIndex].rider_sort_index,
        }
        await orderRepository.updateRiderSortIndex(this.sortingOrder.id, payload)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onSummaryCardClick(key, selection) {
      this.selectedWaybillArray = []
      this.selectedStatusFilters = []
      this.selection = selection
      this.$nextTick(() => {
        this.applyFilters()
      })
      key.split(',').forEach(val => {
        if (val) this.selectedStatusFilters.push(val)
      })
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickEditOrder(id) {
      this.$refs.editOrderModal.openModal(id)
    },
    onClickReverse(id) {
      this.$refs.reverseOrderModal.openModal(id)
    },
    onClickBulkFlagUpdate(id) {
      this.$refs.BulkFlagUpdate.openModal(id)
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselctAll() {
      this.selectedWaybillArray = []
    },
    onClickPrintDispatch(id) {
      this.$router.push({
        name: 'print-pod',
        params: {
          waybillId: id,
          selectedWaybills: this.selectedWaybillArray,
        },
      })
    },
    // REQUIRED
    applyFilters() {
      this.getOrderList()
      this.onClickDeselctAll()
    },
    clearFilters() {
      this.selectedStatusFilters = ''
      this.selectedFlagFilters = ''
      this.selectedBusinessFilters = ''
      this.filters = {}
      this.merchantBusinessOptions = []
      this.$nextTick(() => {
        this.getOrderList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getOrderList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickMakeRemark(id) {
      this.$refs.add_make_remark.openModal(id)
    },
    onClickViewFeedBack(id, url) {
      this.$refs.view_feedback.openModal(id, url)
    },
    onClickFlagDetail(wayBill, statusKey, id) {
      this.$refs.flag_detail.openModal(wayBill, statusKey, id)
    },
    onClickMakeFutureRequest(id) {
      this.$refs.add_future_request.openModal(id)
    },
    onClickBulkAction(key) {
      this.status_key = key
      this.$refs.bulkActionModal.openModal()
    },
    onChangeDefaultWarehouse() {
      this.formStructure = KeyFour.data().formStructure
      this.onClickBulkAction('key_4')
    },
    onAssignPickupRider() {
      this.formStructure = KeyFive.data().formStructure
      this.onClickBulkAction('key_5')
    },
    onCancelClick() {
      this.formStructure = []
      this.onClickBulkAction('key_3')
    },
    onPickupClick() {
      this.formStructure = []
      this.onClickBulkAction('key_6')
    },
    onPickupDeliverClick() {
      this.formStructure = KeySeven.data().formStructure
      this.onClickBulkAction('key_7')
    },
    onDispatchClick() {
      this.formStructure = []
      this.onClickBulkAction('key_8')
    },
    onClickReceiveToOriginWarehouse() {
      this.formStructure = KeyNine.data().formStructure
      this.onClickBulkAction('key_9')
    },
    onClickDispatchFromOriginWarehouse() {
      this.formStructure = this.orderSettings.truck_driver_enabled ? KeyTen.data().formStructure : KeyTen.data().formStructure.splice(0, 3)
      this.onClickBulkAction('key_10')
    },
    onClickReceiveToDestinationWarehouse() {
      this.formStructure = []
      this.onClickBulkAction('key_11')
    },
    onClickAssignDestinationRider() {
      this.formStructure = KeyTwelve.data().formStructure
      this.onClickBulkAction('key_12')
    },
    onClickDeliver() {
      this.formStructure = []
      this.onClickBulkAction('key_13')
    },
    onClickPartiallyDeliver() {
      this.formStructure = KeyFourteen.data().formStructure
      this.onClickBulkAction('key_14')
    },
    onClickReturn() {
      this.formStructure = KeyFifteen.data().formStructure
      this.onClickBulkAction('key_15')
    },
    onClickReschedule() {
      this.formStructure = KeySixteen.data().formStructure
      this.onClickBulkAction('key_16')
    },
    onClickDeliveryFailed() {
      this.formStructure = KeySeventeen.data().formStructure
      this.onClickBulkAction('key_17')
    },
    onClickReturnToOriginWarehouse() {
      this.formStructure = []
      this.onClickBulkAction('key_18')
    },
    onClickFailedReceiveToOriginWarehouse() {
      this.formStructure = []
      this.onClickBulkAction('key_19')
    },
    onClickReturnToClient() {
      this.formStructure = []
      this.onClickBulkAction('key_20')
    },
    onClickReceiveFailedOrderWalkingCustomer() {
      this.formStructure = []
      this.onClickBulkAction('key_22')
    },
    onClickInvalidDestination() {
      this.formStructure = KeyTwentySix.data().formStructure
      this.onClickBulkAction('key_26')
    },
    onClickReturnToOriginWarehouseInvalidDestination() {
      this.formStructure = []
      this.onClickBulkAction('key_27')
    },
    onClickReceivedToOriginWarehouseInvalidDestination() {
      this.formStructure = []
      this.onClickBulkAction('key_28')
    },
    onClickDifferentDestination() {
      this.formStructure = KeyTwentyNine.data().formStructure
      this.onClickBulkAction('key_29')
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getOrderList()
      }
    },
    onClickRefresh() {
      this.getOrderSummary()
      this.getOrderList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
      } else {
        this.selectedWaybillArray = []
      }
    },
    refreshAllOrderSummery() {
      this.loading = true
      localStorage.removeItem('all-orders-summery')
      localStorage.removeItem('all-orders-refresh-time')
      this.getOrderSummary().then(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
.card-transaction {
  margin-bottom: 0 !important;
}
.refresh-button {
  float: right;
}
.refresh-icon {
  color: black !important;
}
.refresh-span {
  margin-bottom: 1px;
  color: black !important;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.timeAgo {
  margin-left: 5px;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dropdown .dropdown-menu {
  max-height: 135px;
  overflow-y: auto;
}
.modal_size {
    width: 75% !important;
    margin: auto;
}
.custom-progress {
    width: 600px;
}
.left-name {
  font-weight: bolder;
  margin-left: 5px;
  margin-top: 3px;
  color: #666666;
}
.right-name {
  margin-left: 3px;
  margin-top: 3px;
  color: #666666;
}
.unable-pointer {
  pointer-events: none;
}
.all-orders-alert .alert {
  line-height: 30px;
}
@media (min-width: 1200px) {
  // .b-table thead tr th:last-child {
  //   position: sticky;
  //   right: 0;
  //   z-index: 0;
  // }
  #all-order-table tbody tr td:last-child {
    background-color: #f8f8f8;
  }
  #all-order-table thead tr th:nth-last-child(2) {
    position: sticky;
    right: 0px;
    z-index: 0;
  }
  #all-order-table tbody tr td:nth-last-child(2) {
    position: sticky;
    right: 0px;
    background-color: #f8f8f8;
    z-index: 0;
  }
}
@media (max-width: 767px) {
  #all-order-table thead th {
    position: sticky;
    top: 0;
  }
  .actionButtons {
    justify-content: end;
  }
}
.shadow-custom {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}
</style>
