<template>
  <b-col
    cols="12"
    md=""
  >
    <b-card
      :style="[cardStyle, cardShadow, cardBorder]"
      :border-variant="borderVariant"
    >
      <b-card-body
        class="summary-card-body"
      >
        <div
          class="transaction-item"
        >
          <b-tooltip
            :target="title"
            variant="primary"
            triggers="hover"
          >
            {{ __numberWithCommas(sum) }}
          </b-tooltip>
          <div
            :id="title"
            class="font-weight-bolder text-center"
          >
            {{ kFormatter(sum) }}
          </div>
          <div
            class="font-weight-bolder text-center"
            style="font-size: 0.8rem"
          >
            {{ title }}
          </div>
          <div
            class="text-center"
            style="font-size: 0.8rem"
          >
            {{ __numberWithCommas(count) }} Orders
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol,
  BCard,
  BCardBody,
  BTooltip,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  name: 'SummaryCard',
  components: {
    BCol,
    BCard,
    BCardBody,
    BTooltip,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    sum: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    borderVariant: {
      type: String,
      default: '',
    },
    bgVariant: {
      type: String,
      default: '',
    },
    textVariant: {
      type: String,
      default: '',
    },
  },
  computed: {
    cardStyle() {
      const style = {
        backgroundColor: this.bgVariant,
        color: this.textVariant,
      }
      return style
    },
    cardShadow() {
      return {
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15) !important',
        transition: 'box-shadow 0.3s ease-in-out !important',
      }
    },
    cardBorder() {
      return {
        border: '1px solid var(--primary) !important',
      }
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
<style scoped>
.summary-card-body {
  padding-top:0.1rem;
  padding-bottom:0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
b-card {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25) !important;
  transition: box-shadow 0.3s ease-in-out, border-width 0.3s ease-in-out !important;
}

b-card:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25) !important;
}
</style>
