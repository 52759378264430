<template>
  <b-modal
    id="modal-order-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE ORDER"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateOrder)"
        >
          <b-row>
            <b-col cols="4" />
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Name"
                label-for="h-name"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.customer_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Customer Name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Address"
                label-for="h-addressLine1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine1"
                  rules="required"
                >
                  <b-form-input
                    id="addressLine1"
                    v-model="form.customer_address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Mobile"
                label-for="Mobile"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="Mobile"
                    v-model="form.customer_phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Customer Mobile"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="orderSettings.second_phone_number"
              cols="6"
            >
              <b-form-group
                label="Second Phone No"
                label-for="customerPhone"
              >
                <b-form-input
                  id="customerPhone"
                  v-model="form.customer_secondary_phone"
                  type="number"
                  placeholder="0xxxxxxxxx"
                  autocomplete="nope"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customer Email"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.customer_email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter the Email Address"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Destination City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    v-model="form.destination_city_id"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select the city"
                    class="col-md-12 ml-n1"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Weight"
                label-for="h-weight"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="COD"
                  rules="required"
                >
                  <b-form-input
                    id="weight"
                    ref="weightInput"
                    v-model="form.weight"
                    type="number"
                    placeholder="1"
                    autocomplete="nope"
                    step=".01"
                    pattern="^\d*(\.\d{0,2})?$"
                    @input="checkWeightRange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="COD"
                label-for="h-cod"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="COD"
                  rules="required"
                >
                  <b-form-input
                    id="cod"
                    v-model="form.cod"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the COD"
                    type="number"
                    maxlength="5"
                    oninput="this.value=this.value.slice(0,this.maxLength)"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row />

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const orderRepository = RepositoryFactory.get('order')
const OrderSettingsRepository = RepositoryFactory.get('orderSetting')

function initialState() {
  return {
    open: false,
    id: null,
    form: {
      order_no: '',
      customer_name: '',
      customer_address: '',
      customer_phone: '',
      customer_secondary_phone: '',
      customer_email: '',
      destination_city_id: '',
      cod: '',
      weight: '',
      description: '',
      remark: '',
      waybill_number: '',
    },
    modalLoading: false,
    loading: false,
    orderSettings: {},
    cityOptions: [],
    stateOptions: [],
    countryOptions: [],
    search: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.id = id
      await this.fetchOrderDetails()
      await this.readOrderSetting()
      await this.fetchCityList()
      this.modalLoading = false
    },
    async fetchOrderDetails() {
      try {
        const { data } = (await orderRepository.getOrderDetailsById(this.id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    checkWeightRange() {
      const minWeight = this.orderSettings.weight_range_setting.min_weight
      const maxWeight = this.orderSettings.weight_range_setting.max_weight
      const currentWeight = parseFloat(this.form.weight)
      if (this.orderSettings.weight_range_setting.enabled === true && (currentWeight < minWeight || currentWeight > maxWeight)) {
        this.validationState = false
        this.validationMessage = `The order weight should be between ${minWeight} Kg to ${maxWeight} Kg`
        this.isMaxWeight = true
      } else {
        this.validationState = true
        this.validationMessage = ''
        this.isMaxWeight = false
      }
      this.$refs.weightInput.setCustomValidity(this.validationMessage)
    },
    async readOrderSetting() {
      this.loading = true
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchCityList() {
      // this.form.city = null
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },

    async onClickUpdateOrder() {
      this.modalLoading = true
      try {
        const payload = {
          general_data: {
            sender_type: 'merchant',
            merchant_id: this.form.merchant.id,
            merchant_business_id: this.form.merchant_business.id,
            origin_city_id: this.form.origin_city_id,
          },
          order_data: [
            {
              order_no: this.form.order_no,
              customer_name: this.form.customer_name,
              customer_address: this.form.customer_address,
              customer_phone: this.form.customer_phone,
              customer_secondary_phone: this.form.customer_secondary_phone,
              customer_email: this.form.customer_email,
              state_id: '',
              destination_city_id: this.form.destination_city_id,
              cod: this.form.cod,
              description: this.form.description,
              weight: this.form.weight,
              remark: '',
            },
          ],
        }
        await orderRepository.updateOrderDetails(this.id, payload)
        this.showSuccessMessage('Order Updated successfully')
        this.$parent.onClickRefresh()
        this.open = false
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss" scoped>
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
