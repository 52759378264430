<template>
  <div>
    <b-tooltip
      target="freeze-info-icon"
      triggers="hover"
      variant="primary"
    >
      <div v-if="status_key === 'key_10'">
        Enabling the freezed warehouse option locks the selected warehouse during order dispatch, ensuring consistent processing and preventing unintended changes.
      </div>
      <div v-if="status_key === 'key_20'">
        Enabling the freeze merchant option locks the selected merchant when updating return to client.
      </div>
    </b-tooltip>
    <feather-icon
      id="freeze-info-icon"
      icon="InfoIcon"
      size="12"
      :style="`position:absolute; top:2; left:${left};`"
      class="text-primary"
    />
  </div>
</template>
<script>
import {
  BTooltip,
} from 'bootstrap-vue'

export default {
  name: 'BFreezeLabelInfo',
  components: {
    BTooltip,
  },
  props: {
    status_key: {
      type: String,
      default: '',
    },
    left: {
      type: Number,
      default: 0,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
}
</script>
