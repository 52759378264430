<template>
  <b-modal
    id="reverse-order-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="REVERSE ORDER STATUS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <h6 class="mb-1">
        Waybill Numbers
      </h6>
      <b-badge
        v-for="(waybill, index) in waybillArray"
        :key="index"
        variant="light-primary"
        class="mr-1 mb-1"
      >
        {{ waybill }}
      </b-badge>
      <ValidationObserver
        ref="reverseOrder"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickReverse)"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Reason"
                label-for="h-name"
                label-cols-md="2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Reason"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.reason"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Reason"
                    type="text"
                    maxlength="125"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <hr>
          <b-row>
            <b-col
              offset-md="4"
              class="d-flex justify-content-end"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mr-1"
                >
                  Clear
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BBadge,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const orderRepository = RepositoryFactory.get('order')

function initialState() {
  return {
    avatarText,
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    waybillArray: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BBadge,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    selected_waybill_array: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.modalLoading = true
      if (id !== undefined) {
        this.fetchOrderDetails()
      } else {
        this.waybillArray = this.selected_waybill_array
        this.modalLoading = false
      }
    },
    async fetchOrderDetails() {
      try {
        const { data } = (await orderRepository.getOrderDetailsById(this.id)).data
        this.waybillArray.push(data.waybill_number)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async onClickReverse() {
      this.modalLoading = true
      try {
        const payload = {
          comment: this.form.reason,
          waybill_numbers: this.waybillArray,
        }
        await orderRepository.reverseOrderStatus(payload)
        this.showSuccessMessage('Order Status Reversed Successfully')
        this.$parent.selectedWaybillArray = []
        this.$parent.getOrderList()
        this.open = false
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
</style>
