<template>
  <b-modal
    id="modal-remark-add"
    ref="modal"
    v-model="open"
    size="ov-custom"
    no-close-on-backdrop
    hide-footer
    centered
    :title="`${ remarkToUpdate ? 'EDIT' : 'ADD' } REMARK`"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          ref="remarkForm"
          class="my-8"
          @submit.prevent="handleSubmit(remarkToUpdate ? onClickUpdate : onClickCreateRemark)"
        >
          <b-row
            v-can="'order-remark-create'"
          >
            <b-col cols="12">
              <b-form-group
                label="Remark"
                label-for="h-name"
                label-cols-md="2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Remark"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.remark"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Remark"
                    type="text"
                    :class="form.remark.length >= maxChar ? 'text-danger' : ''"
                    :maxlength="maxChar"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.remark.length >= maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.remark.length }}</span> / {{ maxChar }}
                  </small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row
            v-can="'order-remark-create'"
          >
            <b-col
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ `${ remarkToUpdate ? 'Update' : 'Submit' }` }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="remarkToUpdate ? remarkToUpdate = null : () => {}"
                >
                  {{ `${ remarkToUpdate ? 'Cancel' : 'Clear' }` }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <br><br>
      <remark-table
        :remarks="items"
        :set-update="setUpdate"
        :order-id="id"
        :data-loading="dataLoading"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import RemarkTable from '@/components/AllOrders/Remark/RemarkTable.vue'

const remarkRepository = RepositoryFactory.get('remark')

function initialState() {
  return {
    open: false,
    id: null,
    form: {
      remark: '',
    },
    modalLoading: false,
    items: [],
    remarkToUpdate: null,
    dataLoading: false,
    maxChar: 190,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    RemarkTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      await this.fetchRemarkDetails()
    },
    async fetchRemarkDetails() {
      this.dataLoading = true
      try {
        const { data } = (await remarkRepository.getRemarkList(this.id)).data
        this.items = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async onClickCreateRemark() {
      this.modalLoading = true
      try {
        const payload = {
          remark: this.form.remark,
        }
        await remarkRepository.createRemark(this.id, payload)
        this.showSuccessMessage('Remark added successfully')
        this.$parent.onClickRefresh()
        this.open = false
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    setUpdate(remark) {
      this.remarkToUpdate = remark
      this.form.remark = remark.remark
    },
    async onClickUpdate() {
      try {
        this.modalLoading = true
        const payload = {
          order_id: this.id,
          remark: this.form.remark,
        }
        const res = await remarkRepository.updateRemark(this.id, this.remarkToUpdate.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Remark updated successfully')
          this.items = this.items.map(remark => {
            if (remark.id === this.remarkToUpdate.id) {
              // eslint-disable-next-line no-param-reassign
              remark.remark = this.form.remark
            }
            return remark
          })
          this.remarkToUpdate = null
          this.$refs.remarkForm.reset()
        } else {
          this.showErrorMessage('Couldn\'t update remark')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
</style>
