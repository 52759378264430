<template>
  <b-modal
    id="modal-flag-detail"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="Flags"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickSetFlag)"
        >
          <b-row>
            <b-col
              md="8"
              xs="12"
            >
              <b-form-group
                label="Action"
                label-for="h-name"
                label-cols-lg="3"
                label-cols-xs="12"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="flag"
                  rules="required"
                >
                  <v-select
                    v-model="selectedFlag"
                    :options="flagOptions"
                    label="title"
                    placeholder="Select the Action"
                    :disabled="isDisabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
              >
                Get Operation
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <hr>
      <b-row class="mt-2">
        <b-col
          md="12"
        >
          <div v-if="action === 0">
            <SetRedelivery
              :waybill="wayBill"
              :set-open="setOpen"
              :refresh="refreshParent"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-alert
            variant="warning"
            :show="form.length === 0"
          >
            <h4 class="alert-heading">
              No Flags Have Been Set For This Order!!
            </h4>
          </b-alert>
        </b-col>

      </b-row>

      <div
        v-for="flag in form"
        :key="flag.key"
      >
        <b-list-group>
          <div
            v-if="flag.type === 'flag_1'"
          >
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-info"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="TruckIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Pickup Request has been placed to be picked up on <b>{{ flag.pivot.data.pickup_at }}</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length > 1"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_2'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-danger"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="AlertOctagonIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Urgent Request have been placed</span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length !== 0"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_3'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-primary"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Exchange Request has been placed with Original Waybill <b>{{ flag.pivot.data.original_waybill_number }}</b> and Exchange to Waybill <b>{{ flag.pivot.data.exchange_waybill_number }}</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length > 2"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_4'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-dark"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Return Request has been placed to Original Waybill <b>{{ flag.pivot.data.original_waybill_number }}</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length > 1"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_5'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-success"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Future Request has been placed to be delivered on <b>{{ flag.pivot.data.future_delivery_date }}</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length > 1"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_6'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-badge
                  variant="light-warning"
                  pill
                  class="badge-round"
                  size="32"
                >
                  <feather-icon
                    icon="UmbrellaIcon"
                    size="32"
                  />
                </b-badge>
              </span>
              <table>
                <tr><span>An Fragile Request have been placed</span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length !== 0"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>

          <div v-if="flag.type === 'flag_7'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-avatar
                  size="25"
                  variant="light-danger"
                >
                  <feather-icon
                    icon="HomeIcon"
                    size="14"
                    class="text-body text-danger align-middle"
                  />
                </b-avatar>
              </span>
              <table>
                <tr><span>The order has been added to a temporary Warehouse <b>({{ flag.pivot.data.temporary_warehouse_name }})</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length !== 0"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.other_data.remark }}</b></span></tr>
              </table>
            </b-list-group-item>
          </div>
          <div v-if="flag.type === 'flag_8'">
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <b-avatar
                  size="25"
                  variant="light-info"
                >
                  <feather-icon
                    icon="RotateCwIcon"
                    size="14"
                    class="text-body align-middle"
                  />
                </b-avatar>
              </span>
              <table>
                <tr><span>The redelivery request has been added to this order by <b>({{ flag.pivot.data.merchant_name }})</b></span></tr>
                <tr
                  v-if="Object.keys(flag.pivot.data).length !== 0"
                >
                  <span class="remark-content">Remark: <b>{{ flag.pivot.data.other_data.remark }}</b></span>
                </tr>
              </table>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BBadge,
  BListGroup,
  BListGroupItem,
  BAlert,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SetRedelivery from './SetRedelivery.vue'
// import vSelect from 'vue-select'

const OrderRepository = RepositoryFactory.get('order')

function initialState() {
  return {
    open: false,
    isDisabled: false,
    id: null,
    wayBill: null,
    statusKey: null,
    selectedFlag: null,
    action: null,
    modalLoading: false,
    refreshParent: null,
    flagOptions: [],
    loading: false,
    form: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    SetRedelivery,
    BButton,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BBadge,
    BAlert,
    vSelect,
    BAvatar,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(wayBill, statusKey, id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.wayBill = wayBill
      this.id = id
      this.statusKey = statusKey
      this.filterFlagOptions()
      this.fetchRemarkDetails()
      this.refreshParent = this.$parent.onClickRefresh
    },
    onClickSetFlag() {
      switch (this.selectedFlag.value) {
        case 0:
          this.action = 0
          break
        default:
          this.showErrorMessage('Please Select an Action')
      }
    },
    filterFlagOptions() {
      if (this.statusKey === 'key_17' || this.statusKey === 'key_18' || this.statusKey === 'key_19') {
        this.flagOptions = [
          { value: 0, title: 'Redelivery' },
        ]
      } else {
        this.flagOptions = [
          { value: 1, title: 'No Actions Found for this Status' },
        ]
        this.isDisabled = true
        this.selectedFlag = 'No Actions Found for this Status'
      }
    },
    async fetchRemarkDetails() {
      try {
        const { data } = (await OrderRepository.getOrderFlagDetailsById(this.id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    setOpen(val) {
      this.open = val
    },
  },
}
</script>
<style lang="scss">
.remark-content{
    word-break: break-all;
  }
</style>
