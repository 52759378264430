<template>
  <b-modal
    id="reverse-order-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="BULK FLAG UPDATE"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <h6 class="mb-1">
        Waybill Numbers
      </h6>
      <b-badge
        v-for="(waybill, index) in waybillArray"
        :key="index"
        variant="light-primary"
        class="mr-1 mb-1"
      >
        {{ waybill }}
      </b-badge>
      <hr>
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickSetFlag)"
        >
          <b-row>
            <b-col
              md="8"
              xs="12"
            >
              <b-form-group
                label="Action"
                label-for="h-name"
                label-cols-lg="3"
                label-cols-xs="12"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="flag"
                  rules="required"
                >
                  <v-select
                    v-model="selectedFlag"
                    :options="flagOptions"
                    label="title"
                    placeholder="Select the Action"
                    :disabled="isDisabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xs="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
              >
                Get Operation
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <hr>
      <b-row class="mt-2">
        <b-col
          md="12"
        >
          <div v-if="action === 0">
            <setRedelivery
              :waybill="waybillArray"
              :set-open="setOpen"
              :refresh="refreshParent"
            />
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BBadge,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SetRedelivery from './SetRedelivery.vue'

const orderRepository = RepositoryFactory.get('order')
function initialState() {
  return {
    avatarText,
    modalLoading: false,
    waybillArray: [],
    open: false,
    isDisabled: false,
    loading: false,
    wayBill: null,
    id: null,
    statusKey: null,
    selectedFlag: null,
    action: null,
    form: {},
    refreshParent: null,
    flagOptions: [
      { value: 0, title: 'Redelivery' },
    ],
  }
}
export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BBadge,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BOverlay,
    vSelect,
    SetRedelivery,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    selected_waybill_array: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.modalLoading = true
      // this.filterFlagOptions()
      if (id !== undefined) {
        this.fetchOrderDetails()
      } else {
        this.waybillArray = this.selected_waybill_array
        this.modalLoading = false
      }
    },
    async fetchOrderDetails() {
      try {
        const { data } = (await orderRepository.getOrderDetailsById(this.id)).data
        this.waybillArray.push(data.waybill_number)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    onClickSetFlag() {
      switch (this.selectedFlag.value) {
        case 0:
          this.action = 0
          break
        default:
          this.showErrorMessage('Please Select an Action')
      }
    },
    setOpen(val) {
      this.open = val
    },
  },
}
</script>
<style scoped>
</style>
