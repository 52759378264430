<template>
  <b-modal
    id="modal-view-feeback"
    ref="modal"
    v-model="open"
    size="lg"
    class="modal_size"
    no-close-on-backdrop
    hide-footer
    centered
    :title="`VIEW FEEDBACK`"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="10"
        >
          <b-form-input
            ref="code"
            type="text"
            :value="url"
          />
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="2"
        >
          <b-button
            type="button"
            variant="primary"
            @click="copyCode()"
          >
            COPY
          </b-button>
        </b-col>
      </b-row>
      <br><br>
      <b-row>
        <b-table
          :items="items"
          :fields="fields"
          hover
          responsive
          striped
          show-empty
        >
          <template #empty>
            <TableDataFetching
              :rows="items"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(waybill_number)="data">
            {{ data.value }}
          </template>
          <template #cell(description)="data">
            {{ data.value }}
          </template>
          <template #cell(rating)="data">
            <star-rating
              :rating="data.value"
              :max-rating="5"
              :star-size="25"
              :show-rating="false"
              :read-only="true"
            />
          </template>
        </b-table>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import StarRating from 'vue-star-rating'

const feedBackRepository = RepositoryFactory.get('feedback')

function initialState() {
  return {
    open: false,
    id: null,
    url: null,
    form: {},
    dataLoading: true,
    items: [],
    remarkToUpdate: null,
    fields: [
      { key: 'waybill_number', label: 'Waybill Number' },
      { key: 'description', label: 'Description' },
      { key: 'rating', label: 'Rating' },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BButton,
    StarRating,
    BTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async mounted() {
      this.fetchFeedbackDetails()
    },
    async openModal(id, url) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.url = url
      await this.fetchFeedbackDetails()
    },
    copyCode() {
      const url = this.$refs.code
      url.select()
      document.execCommand('copy')
      this.showSuccessMessage('Url Copied')
    },
    async fetchFeedbackDetails() {
      try {
        const { data } = (await feedBackRepository.getFeedbackDetails(this.id)).data
        this.items = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
