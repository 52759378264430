import Sortable from 'sortablejs'

// eslint-disable-next-line arrow-body-style
const createSortable = (el, options) => {
  if (options) {
    return Sortable.create(el, {
      ...options,
    })
  }
  return null
}

const sortable = {
  name: 'sortable',
  bind(el, binding, vnode) {
    const table = el.querySelector('table')
    // eslint-disable-next-line no-underscore-dangle
    table._sortable = createSortable(
      table.querySelector('tbody'),
      binding.value,
      vnode,
    )
  },
}

export default sortable
