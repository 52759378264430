<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <ValidationObserver
            v-slot="{ handleSubmit }"
            slim
          >
            <b-form
              class="my-8"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label="Waybill Number"
                    label-for="waybill"
                  >
                    <b-form-input
                      id="waybill"
                      ref="waybill"
                      v-model="form.waybill_number"
                      type="text"
                      autocomplete="nope"
                      :disabled="loadingResponse"
                      @paste="handlePaste"
                      @keydown="handleKeyDown"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="auto">
                  <WaybillSearch
                    :waybill-number="form.waybill_number"
                    @search="getOrderDetails(form.waybill_number)"
                  />
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label="Warehouse"
                    label-for="warehouse"
                  >
                    <v-select
                      :key="formKey"
                      v-model="form.warehouse_id"
                      :options="warehouseOptions"
                      :reduce="option => option.id"
                      label="name"
                      :disabled="inputDisabled"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- submit and reset -->
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-button
                    type="submit"
                    class="vh-5 mt-2 ml-1 ml-md-1 custom-button-color"
                    :disabled="!(form.waybill_number && form.warehouse_id) || loadingResponse"
                  >
                    {{ loadingResponse ? 'Submitting...' : 'Submit' }}
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-card>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Confirmed Date"
            label-for="basicInput"
          >
            <div class="position-relative">
              <flat-pickr
                id="v-ref-no"
                v-model="filters['status_date']"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                class="form-control"
                placeholder="Search"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <div class="m-2">
        <b-row cols="12">
          <b-col
            md="12"
            lg="4"
            class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
          >
            <b-button
              :disabled="selectedWaybillArray.length === 0"
              class="mr-1 custom-button-color"
              @click="onClickBulkAction()"
            >
              Bulk Submit
            </b-button>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
          >
            <b-button
              type="submit"
              class="custom-button-color-blur"
              :disabled="false"
            >
              Scanned Orders : {{ submitCount }}
            </b-button>
          </b-col>
          <b-col
            class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
          >
            <ReportTableButtons
              :items="rows"
              :json_fieldz="json_fields"
              :name="`DefaultWarehouseChange - Page ${page}.xls`"
              :bulkname="`DefaultWarehouseChange.xls`"
              :fetch="getOrderListNoPagination"
              @refresh="onClickRefresh"
            />
          </b-col>
        </b-row>
        <b-row>
          <div class="font-weight-bolder text-muted ml-1 mt-1">
            {{ `${selectedWaybillArray.length} record${selectedWaybillArray.length === 1 ? '':'s'}` }} selected
          </div>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
              @sort-changed="sortChanged"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #head(id)>
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template #cell(id)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedWaybillArray"
                        :value="data.item.waybill_number"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(waybill_number)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.customer_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      :class="{'font-weight-bolder ml-1 text-danger': data.item.temporary_warehouse_id !=null ,'font-weight-bolder ml-1 text-primary':data.item.temporary_warehouse_id ===null}"
                      @click="onClickViewOrderModal(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(merchant.name)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="">
                      {{ __orderMerchantName(data.item) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                    <div class="font-small-2">
                      {{ data.item.customer_address }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(origin_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.origin_city.name }}
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(destination_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.destination_city.name }}
                      <!--                    </b-badge>-->
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(finance)="data">
                <div
                  v-if="__getMerchantType(data.item) !== 'WC'"
                  class="width-150"
                >
                  <b>Total&nbsp;:</b>&nbsp;&nbsp;{{ data.item.cod || '---' }}
                  <div style="height:8px;" />
                  <b>Collected&nbsp;:</b>&nbsp;&nbsp;{{ data.item.collected_cod || '---' }}
                  <div style="height:8px;" />
                  <b>D-Charge&nbsp;:</b>&nbsp;&nbsp;{{ data.item.delivery_charge || '---' }}
                </div>
                <div
                  v-else
                  class="width-150"
                >
                  <b>D-Charge&nbsp;:</b>&nbsp;&nbsp;{{ data.item.delivery_charge || '---' }}
                  <div style="height:8px;" />
                  <b>Advance&nbsp;:</b>&nbsp;&nbsp;{{ data.item.collected_cod || '---' }}
                  <div style="height:8px;" />
                  <b>Balance&nbsp;:</b>&nbsp;&nbsp;{{ (data.item.delivery_charge - data.item.collected_cod ) || '---' }}
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(status.key)="data">
                <order-status-badge :status-key="data.value" />
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickViewOrderModal(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <bulk-action-modal
      ref="bulkActionModal"
      :form_structure="formStructure"
      :status_key="status_key"
      :selected_waybill_array="selectedWaybillArray"
    />
    <view-order-modal ref="viewOrderModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BPagination,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import flatPickr from 'vue-flatpickr-component'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import BulkActionModal from './bulk-actions/BulkActionModal.vue'
import AutoFetch from './AutoFetch'
import WaybillSearch from './WaybillSearch/WaybillSearch.vue'

const orderRepository = RepositoryFactory.get('order')
const ResourceRepository = RepositoryFactory.get('resource')
const OrderSettingsRepository = RepositoryFactory.get('orderSetting')

export default {
  name: 'KeyFour',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    flatPickr,
    BAvatar,
    BRow,
    BCol,
    BTable,
    BCard,
    BCardActions,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BPagination,
    BOverlay,
    OrderStatusBadge,
    BDropdown,
    BDropdownItem,
    BulkActionModal,
    BFormCheckbox,
    ViewOrderModal,
    vSelect,
    WaybillSearch,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, AutoFetch],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    status_key: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // Table Info
      submitCount: 0,
      loadingResponse: false,
      avatarText,
      dataLoading: false,
      filters: {},
      total: 0,
      meta: {},
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterName: '',
      warehouseOptions: [],
      fields: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'waybill_number',
          label: 'WayBill Number',
          sortable: true,
        },
        {
          key: 'merchant.name',
          label: 'Merchant',
          sortable: true,
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'origin_warehouse',
          label: 'Origin',
          sortable: true,
        },
        {
          key: 'destination_warehouse',
          label: 'Destination',
          sortable: true,
        },
        {
          key: 'customer_email',
          label: 'Customer',
          sortable: true,
        },
        {
          key: 'finance',
          label: 'Finance',
          sortable: false,
        },
        {
          key: 'weight',
          label: 'Weight',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        Merchant: {
          field: 'merchant.name',
          callback: value => `"${value}"`,
        },
        'Origin City': {
          field: 'origin_city.name',
          callback: value => `"${value}"`,
        },
        'Origin Warehouse': {
          field: 'origin_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Destination City': {
          field: 'destination_city.name',
          callback: value => `"${value}"`,
        },
        'Destination Warehouse': {
          field: 'destination_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: 'cod',
        'Collected Cod': 'collected_cod',
        'Delivery Charge': 'delivery_charge',
        weight: 'weight',
        Status: 'status.name',
      },
      rows: [],
      // Form Info
      form: {},
      formStructure: [
        {
          type: 'input',
          placeholder: '',
          inputType: 'text',
          title: 'Waybill Number',
          value: 'waybill_number',
          class: 'col-6 col-md-3',
          validation: 'required',
        },
        {
          type: 'select',
          placeholder: '',
          title: 'Warehouse',
          value: 'warehouse_id',
          class: 'col-6 col-md-3',
          fetch: ResourceRepository.getWarehouseListForDropDown,
          fetchData: localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : null,
          fetchFilter: warehouse => ({ label: `${warehouse.name} - ${warehouse.ref_no}`, value: warehouse.id }),
          multiple: false,

        },
      ],
      selectedWaybillArray: [],
      waybillData: [],
      formKey: 0,
      inputDisabled: true,
      orderSettings: null,
      selectAllCheckbox: false,
      waybillSeriesList: [],
      prefixList: [],
      matchingSeries: [],
      prefixMatch: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.getOrderList()
    },
    perPage() {
      this.getOrderList()
    },
    waybillData: {
      handler(val) {
        let waybillLocated = false
        val.forEach((data, index) => {
          if (data.waybill_number && this.form.waybill_number) {
            if (data.waybill_number.toLowerCase() === this.form.waybill_number.toLowerCase()) {
              waybillLocated = true
              if (!this.form.weight || (this.waybillData.length > 1 && this.waybillData[index - 1] !== {})) {
                this.$set(this.form, 'warehouse_id', data.origin_warehouse.id || '')
              }
              this.formKey += 1
              this.inputDisabled = false
              this.$refs.waybill.focus()
            }
          }
        })
        if (!waybillLocated) {
          this.$set(this.form, 'warehouse_id', '')
          this.inputDisabled = true
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getWarehouseList()
    await this.readOrderSetting()
    await this.getWaybillSeriesList()
    this.inputDisabled = true
  },
  methods: {
    handlePaste(event) {
      // Access the pasted value from the event
      const pastedText = event.clipboardData.getData('text')
      // Call your function with the pasted value or perform actions as needed
      this.getOrderDetails(pastedText)
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        this.getData()
      }
    },
    getData() {
      this.getOrderDetails(this.form.waybill_number)
    },
    async getOrderDetails(waybill) {
      try {
        const { data } = (await orderRepository.fetchOrderData(waybill)).data
        this.waybillData.push(data)
      } catch (e) {
        this.waybillData.push({})
      }
    },
    async getOrderList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderListByKey(this.status_key, this.page, this.perPage, this.filterQuery))
        this.rows = data.data
        this.meta = data.meta
        this.submitCount = 0
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getOrderListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderListByKeyNoPagination(this.status_key, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getWarehouseList() {
      try {
        this.warehouseOptions = localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : (await ResourceRepository.getWarehouseListForDropDown()).data.data
        if (!localStorage.getItem('warehouse_list')) localStorage.setItem('warehouse_list', JSON.stringify(this.warehouseOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readOrderSetting() {
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onSubmit() {
      this.loadingResponse = true
      try {
        const payload = {
          waybill_numbers: [],
        }
        payload.waybill_numbers.push(this.form.waybill_number)
        payload.status_key = this.status_key
        payload.other_details = {
          warehouse_id: this.form.warehouse_id,
        }

        await orderRepository.updateOrderStatus(payload)
        this.waybillData = []
        // await this.getOrderList()
        this.showSuccessMessage('Order updated successfully')
        this.submitCount += 1
        this.clearFormData()
      } catch (e) {
        this.convertAndNotifyError(e)
        this.clearFormData()
      }
    },
    clearFormData() {
      this.$set(this.form, 'waybill_number', '')
      this.$set(this.form, 'warehouse_id', '')
      this.loadingResponse = false
      this.$nextTick(() => {
        this.$refs.waybill.focus()
      })
    },
    onClickViewOrderModal(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickBulkAction() {
      this.$refs.bulkActionModal.openModal()
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselctAll() {
      this.selectedWaybillArray = []
    },
    filterQueryUpdate() {
      this.getOrderList()
      this.onClickDeselctAll()
    },
    handleChangePage(page) {
      this.page = page
      this.getOrderList()
    },
    changePage(value) {
      this.perPage = value
    },
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchOrderList()
      }
    },
    onClickRefresh() {
      this.getOrderList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
      } else {
        this.selectedWaybillArray = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
